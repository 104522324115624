<template>
	<div class="order_wrap">
		<v-container class="">
			<div class="message_box py-8 px-6">
				<h3 class="d1 mb-2"><strong>전자 서명을 취소하셨습니다.</strong></h3>
				<span
					>계약을 진행하길 원하시는 경우 담당 판매인에게 연락 바랍니다.</span
				>
			</div>
		</v-container>
		<!-- <div class="bottom_box px-6">
			<v-btn color="primary" class="col_1_small" @click="complete()">
				{{ btnMsg }}
			</v-btn>
		</div> -->
	</div>
</template>

<script>
import computeds from '@/mixins/computeds'
import { registerCallbackToApp, closePopView } from '@/utils/navigation'
export default {
	mixins: [computeds],
	created() {
		this.initAppPage()
	},
	data() {
		return {
			custSelf: false, // 직접입력: true, 코디대행: false
			btnMsg: ''
		}
	},
	async mounted() {
		this.custSelf = this.$store.getters['verify/custSelf']
		if (window.BMManager) {
			this.btnMsg = '확인'
		} else {
			this.btnMsg = '코웨이 홈페이지로 이동'
		}
	},
	methods: {
		complete() {
			this.$log('complete')
			if (window.BMManager) {
				this.$router.push({ name: 'order-status' })
			} else {
				location.href = `${this.cowayHome}`
			}
		},
		async initAppPage() {
			registerCallbackToApp(
				{
					cbPhysicalBack: 'cbPhysicalBack',
					isNotUseDsHeaderPhysicalBack: true
				},
				this
			)
		},
		async cbPhysicalBack() {
			const ok = await this.$alert({
				message: '홈화면으로 이동하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			})
			if (ok) closePopView()
		}
	}
}
</script>

<style scoped lang="scss"></style>
